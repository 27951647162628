section.home-page {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}
section.home-page .nav {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 70px;
    background-color: #fff;
    font-size: 26px;
    -webkit-box-shadow: 0px 5px 20px -5px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 5px 20px -5px rgba(66, 68, 90, 1);
    box-shadow: 0px 5px 20px -5px rgba(66, 68, 90, 1);
    gap: 10px;
}


section.home-page .nav span{
    display: flex;
    justify-content: center;
    font-family: "Audiowide", sans-serif;
    letter-spacing: 1px;
    align-items: center;
    font-size: 26px;
}
