div.players-content{
    display: flex;
    position: relative;
    width: 100%;
    min-height: 180px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
span.number-of-players {
    display: flex;
    height: 40px;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    background-color: #000000;

    margin-bottom: 20px;
    color: white;
    font-weight: 300;
}


span.number-of-players span{
    display: flex;
    width: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #fffdfd;
    color: black;
    margin-left: 10px;
}