div.navigation {
    display: flex;
    top: 15px;
    font-size: 20px;
    right: 20px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #fff;
    color: black;
    z-index: 1000;
    -webkit-box-shadow: 0px 5px 20px -5px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 5px 20px -5px rgba(66, 68, 90, 1);
    box-shadow: 0px 5px 20px -5px rgba(66, 68, 90, 1);
}
