@media screen and (max-width: 500px) {
    div.leave-room-button {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 40px;
        align-items: center;
        width: 90%;
        height: 60px;
        border-radius: 15px;
        background-color: rgb(203, 29, 29);
        color:white;
        font-size: 15px;
        font-weight: 400;
        -webkit-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
        cursor: pointer;
    }
 }
    
    @media screen and (min-width: 501px) and (max-width: 700px) {
        div.leave-room-button {
            display: flex;
            justify-content: center;
            margin-top: 40px;
            margin-bottom: 40px;
            align-items: center;
            width: 400px;
            height: 60px;
            border-radius: 15px;
            background-color: rgb(203, 29, 29);
            color:white;
            font-size: 15px;
            font-weight: 400;
            -webkit-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
            -moz-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
            box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
            cursor: pointer;
        }
    }
    
    
    @media screen and (min-width: 701px)  {
        div.leave-room-button {
            display: flex;
            justify-content: center;
            margin-top: 40px;
            margin-bottom: 40px;
            align-items: center;
            width: 450px;
            height: 60px;
            border-radius: 15px;
            background-color: rgb(203, 29, 29);
            color:white;
            font-size: 15px;
            font-weight: 400;
            -webkit-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
            -moz-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
            box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
            cursor: pointer;
        }
    }