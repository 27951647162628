
@media screen and (max-width: 500px) {
    div.my-profile-confirm-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
        border-radius: 15px;
        background-color: black;
        color: white;
        font-weight: 300;
        -webkit-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
        cursor: pointer;
        margin-bottom: 20px;
    }
    }
    
    
    @media screen and (min-width: 501px) and (max-width: 700px) {
        div.my-profile-confirm-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 350px;
            height: 60px;
            border-radius: 15px;
            background-color: black;
            color: white;
            font-weight: 300;
            -webkit-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
            -moz-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
            box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
            cursor: pointer;
            margin-bottom: 20px;
        }
    }
    
    
    @media screen and (min-width: 701px)  {
        div.my-profile-confirm-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 450px;
            height: 60px;
            border-radius: 15px;
            background-color: black;
            color: white;
            font-weight: 300;
            -webkit-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
            -moz-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
            box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
            cursor: pointer;
            margin-bottom: 20px;
        }
    }
    



span.error-message {
    margin-top: 20px;
    margin-bottom: 20px;
    color: red;
}