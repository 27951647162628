@media screen and (max-width: 500px) {
    div.invitation-item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 90%;
        height: 80px;
        padding: 10px 10px 10px 10px;
        border-radius: 10px;
        background-color: rgb(255, 255, 255);
        -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        margin-bottom: 15px;
    }
}


@media screen and (min-width: 501px) and (max-width: 700px) {
    div.invitation-item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 350px;
        height: 80px;
        padding: 10px 10px 10px 10px;
        border-radius: 10px;
        background-color: rgb(255, 255, 255);
        -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        margin-bottom: 15px;

    }
}

@media screen and (min-width: 701px) {
    div.invitation-item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 450px;
        height: 80px;
        padding: 10px 10px 10px 10px;
        border-radius: 10px;
        background-color: rgb(255, 255, 255);
        -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        margin-bottom: 15px;

    }
}



div.invitation-item .room-name{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    width: 150px;
    height: 100%;
    gap: 3px;
}

div.invitation-item .room-name .name{
    font-size: 18px;
}

div.invitation-item .label{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: gray;
}

div.invitation-item .actions{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 100%;
    gap: 10px;

}

div.invitation-item .actions .accept, div.invitation-item .actions .decline{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 130px;
    gap: 5px;
}




div.invitation-item .actions .icon{
    display: flex;
    font-size: 25px;
    cursor: pointer;
}

div.invitation-item .icon-mail{
    display: flex;
    font-size: 40px;
}