div.invitations-content {
    display: flex;
    position: relative;
    width: 100%;
    min-height: 180px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
div.invitations-content form {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


div.room-is-full{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 75px;
    width: 100%;
    margin-bottom: 30px;
    padding: 15px ;
    border-radius: 15px;
    background-color: #dcdada;
    -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
}


span.lack-of-text {
    margin-bottom: 20px;
}