div.ranking-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0px 10px;
    border-radius: 15px;
    margin-bottom: 10px;
    background-color: rgb(24, 21, 21);
    -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    color:white;
    font-weight: 300;
}


@media screen and (max-width: 380px) {
    div.ranking-item span.rank  {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffd4d4;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        color: black;
    }
    div.ranking-item span.name  {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #3c3c3c;
        width: 90px;
        height: 25px;
        border-radius: 10px;
        color: white;
        overflow: hidden;
        font-size: 14px;
    }
 }


 @media screen and (min-width: 381px) {
    div.ranking-item span.rank  {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffd4d4;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: black;
    }
    div.ranking-item span.name  {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #3c3c3c;
        width: 120px;
        height: 30px;
        border-radius: 10px;
        color: white;
        overflow: hidden;
        font-size: 15px;
    }
 }


div.ranking-item span.wrapper   {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 110px;
    height: 30px;
}

div.ranking-item span.wrapper span.box  {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 10px;
    background-color: rgb(255 240 240);
    color: black;
}
div.ranking-item span.total-prize {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 30px;
    height: 30px;
    background-color: rgb(144 8 8);
}
