div.list-item {
    display: flex;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    margin-bottom: 20px;
    background-color: white;
    border-radius: 15px;
    -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
}

div.list-item span.nr {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: white;
    background-color: black;
}
div.list-item span.email {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 30px;
    overflow: auto;
}

div.list-item span.action {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
}

div.list-item .remove-player-icon {
    font-size: 24px;
    cursor: pointer;

}

div.list-item .remove-player-icon:hover {
    font-size: 26px;
    cursor: pointer;
}