
    div.description-item {
        display: flex;
        align-items: center;
        width: 300px;
        gap: 10px;
        padding-left: 20px;
        margin-bottom: 2px;
    }
    
    
div.description-item .description {
    font-size: 13px;
    font-weight: 400;
}


div .description-item .fifa-description {
    display: flex;
    height: 20px;
    width: 20px;
    font-size: 13px;
    font-weight: 300;
}

