@media screen and (max-width: 500px) {
  .slider-container {
    margin-top: 20px;
    width: 80%;
    color: black;
  }
}
  
  
  @media screen and (min-width: 501px) and (max-width: 700px) {
    .slider-container {
      margin-top: 20px;
      width:  350px;
      color: black;
    }
  }
  
  
  @media screen and (min-width: 701px) and (max-width: 950px)  {
    .slider-container {
      margin-top: 20px;
      width: 80vw;
      color: black;
    }
  }

  @media screen and (min-width: 951px)  {
    .slider-container {
      margin-top: 20px;
      width: 800px;
      color: black;
    }
  }

.slider-container .item {
  border-radius: 30px;
  overflow: hidden;
}

.slider-container img {
  width: 100%;
}

.slider-container .slick-prev:before, 
.slider-container .slick-next:before {
  color: black;
  font-size: 37px; 
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

.slider-container .slick-prev, 
.slider-container .slick-next {
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  z-index: 1;
}

.slider-container .slick-prev {
  left: -30px; 
}

.slider-container .slick-next {
  right: -30px; 
}
