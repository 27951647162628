div.official-score-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
    background-color: black;

    /* background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1); */

    border-radius: 15px;
    margin-bottom: 10px;
    padding: 0px 20px 20px 20px;
    overflow: hidden;
    font-weight: 300;
}


div.official-score-item .top{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 20px;
    border-radius: 0px 0px 5px 5px;
    width: 100px;
    color: black;
    background-color: #ece8e8;
}


div.official-score-item .bottom{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 10px 10px;
    height: 94px;
    width: 100%;
    /* background-color: red; */
}


div.official-score-item span.flag{
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
}


@media screen and (max-width: 500px) {
    div.official-score-item span.country-name{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 25px;
        /* background-color: white; */
        color: white;
        /* color: black; */

        font-size: 20px;
    }
 }
    
    
    @media screen and (min-width: 501px)  {
        div.official-score-item span.country-name{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 25px;
            /* background-color: white; */
            /* color: black; */
            color: white;
            font-size: 20px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }



div.official-score-item span.score{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background-color: rgb(89, 89, 89);
    /* background-color: rgb(24, 21, 21); */

    color: white;
    border-radius: 10px;
    font-size: 20px;
    /* -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1); */

}

div.official-score-item span.separator{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 25px;
    background-color: rgb(60, 58, 58);
    /* color: black; */
    color: white;
}
