@media screen and (max-width: 500px) {
    section.room-panel {
        display: flex;
        align-items: center;
        width: 98%;
        flex-direction: column;
        margin-top: 20px;
        background-color: white;
        border-radius: 15px;
        -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        padding: 10px;
    }
 }
    
    @media screen and (min-width: 501px) and (max-width: 700px) {
        section.room-panel {
            display: flex;
            align-items: center;
            width: 400px;
            flex-direction: column;
            margin-top: 20px;
            background-color: white;
            border-radius: 15px;
            -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            padding: 10px;
        }
    }
    
    
    @media screen and (min-width: 701px)  {
        section.room-panel {
            display: flex;
            align-items: center;
            width: 450px;
            flex-direction: column;
            margin-top: 20px;
            background-color: white;
            border-radius: 15px;
            -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            padding: 10px;
        }
    }


section.room-panel .settings-nav {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 50px;
    flex-direction: row;
    margin-bottom: 20px;
    cursor: pointer;
}

section.room-panel .settings-nav .action-button{
    display: flex;
    justify-content: center;
    width: 50%;
}
section.room-panel .settings-nav .action-button.active{
    font-weight: 600;
    border-bottom: 2px solid black;
}

