div.bet-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px 20px 0px 20px;
    height: 120px;
    background-color: rgb(0, 0, 0);
    border-radius: 15px;
    margin-bottom: 10px;
    font-weight: 300;
    overflow: hidden;
}

div.bet-item .top{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 20px;
    border-radius: 0px 0px 5px 5px;
    width: 100px;
    color: black;
    background-color: #ece8e8;
}

div.bet-item .middle{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;

}

div.bet-item .bottom{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 10px 10px;
    height: 50px;
    width: 100%;
}
div.bet-item .bottom .action{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 25px;
    border-radius: 7px;
    background-color: #fff;
    cursor: pointer;
}
div.bet-item span.flag{
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    overflow: hidden;
}




@media screen and (max-width: 500px) {
    div.bet-item span.country-name{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 25px;
        color: white;
        font-size: 20px;
    }
 }
    
    @media screen and (min-width: 501px)  {
        div.bet-item span.country-name{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 25px;
            color: white;
            font-size: 20px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
div.bet-item select.score{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background-color: white;
    border: none;
    outline: none;
    color: black;
    border-radius: 10px;
    font-size: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: none;
    text-align: center;
    text-align-last: center;

}


div.bet-item span.separator{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 25px;
    background-color: rgb(60, 58, 58);
    color: white;
}
