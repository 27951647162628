
@media screen and (max-width: 500px) {
    a.list-element {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        width: 90%;
        height: 75px;
        border-radius: 15px;
        background-color: white;
        -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        color: black;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 18px;
    }
}

@media screen and (min-width: 501px) and (max-width: 700px) {
    a.list-element {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        width: 350px;
        height: 75px;
        border-radius: 15px;
        background-color: white;
        -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        color: black;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 18px;
    }
}


@media screen and (min-width: 701px) {
    a.list-element {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        width: 400px;
        height: 75px;
        border-radius: 15px;
        background-color: white;
        -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        color: black;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 18px;
    }
}



a.list-element .owner-title {
    font-size: 13px;
    font-weight: 400;
}