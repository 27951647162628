label.product {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    /* width: 100%; */
    margin-bottom: 10px;
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: white;
    box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
}

label.product .name{
    display: flex;
    margin-right: 10px;
}
label.product .icon{
    display: flex;
    margin-right: 5px;
    margin-left: 10px;
}
