@media screen and (max-width: 700px) {

    div.navigation-menu {
        position: fixed;
        display: flex;
        flex-direction: column;
        left: -100%;
        /* top: 70px; */
        width: 80%;
        height: calc(100%);
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.889);
        transition: 0.5s;
        padding-top: 60px;
        gap: 10px;
    }
}

@media screen and (min-width: 701px) {

    div.navigation-menu {
        position: fixed;
        display: flex;
        flex-direction: column;
        left: -100%;
        width: 360px;
        height: calc(100%);
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.889);
        transition: 0.5s;
        padding-top: 60px;
        gap: 10px;
    }
}



div.navigation-menu.active {
    left: 0;
}

div.navigation-menu > a{ 
    display: flex;
    height: 60px;
    font-weight: 300;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: white;
    
}