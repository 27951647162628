@media screen and (max-width: 500px) {
    div.my-bets-list {
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: center;
        padding: 15px;   
        border-radius: 15px;
        width: 350px;
        background-color: rgb(255, 255, 255);
        -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    }
 }
    
    
    @media screen and (min-width: 501px)  {
        div.my-bets-list {
            display: flex;
            flex-direction: column; 
            justify-content: center;
            align-items: center;
            padding: 15px;   
            border-radius: 15px;
            width: 400px;
            background-color: rgb(255, 255, 255);
            -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        }
    }