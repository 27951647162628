section.payment-failed-page {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    height: 600px;
    margin-top: 80px;
    gap: 50px;
}

section.payment-failed-page > img {
    width: 200px;
    height: 200px;
    margin-top: 40px;
    transition: transform 0.5s ease-in-out;
    animation: pulse 1.4s 0.2s; 
}

section.payment-failed-page .failed-title {
    display: flex;
    font-size: 25px;
    font-weight: 300;
}

section.payment-failed-page .back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 60px;
    border: none;
    border-radius: 10px;
    background-color: black;
    color: white;
    font-size: 18px;
    transition: 0.5s;
    cursor: pointer;
}
section.payment-failed-page .back-button:hover {
    transform: scale(1.1);
}

@keyframes pulse {
    0%, 100% {
        transform: scale(1);
    }
  
    50% {
        transform: scale(1.2);
    }
}