div.table-item {
    display: flex;
    width: 100%;
    height: 50px;
    border-bottom: 0.5px solid rgb(230, 227, 227);
    font-size: 15px;
}
div.table-item .rank{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
}

div.table-item .rank .circle{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0);
    width: 30px;
    height: 30px;
    color: white;
    border-radius: 50%;
}



div.table-item .country{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    padding-left: 5px;
}

div.table-item .country .box{
    display: flex;
    align-items: center;
    /* gap: 10px; */
    width: 170px;
}

div.table-item .country .box .flag{
    width: 25px;
    height: 25px;
    border-radius: 7px;
    overflow: hidden;
    margin-right: 10px;
}
div.table-item .country .box .fifa{
    margin-left: 5px;
    font-size: 13px;
    font-weight: 300;
}

