div.custom-settings-input.active{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 75px;
    width: 100%;
    margin-bottom: 30px;
    padding: 15px ;
    border-radius: 15px;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    transition: 0.1s;
}

div.custom-settings-input  {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 75px;
    width: 100%;
    margin-bottom: 30px;
    padding: 15px ;
    border-radius: 15px;
    background-color: #e6e0e0;
    -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    transition: 0.1s;
}

div.custom-settings-input .left {
    display: flex;
    width: 80%;
    flex-direction: column;
}
div.custom-settings-input .right {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    width: 20%;
}


div.custom-settings-input:focus-within {
    -webkit-box-shadow: 0px 0px 10px -5px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -5px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -5px rgba(66, 68, 90, 1);
}

div.custom-settings-input label {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    color: rgb(38, 38, 38);
} 
div.custom-settings-input label span {
    color: rgb(47, 44, 224);
}

div.custom-settings-input input {
    height: 25px;
    border: none;
    outline: none;
    font-size: 15px;
    color: rgb(38, 38, 38);
    background-color: transparent;
}