
article.article-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgb(255, 255, 255);
}


article.article-panel:last-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding-bottom: 30px;
}
article.article-panel span.title {
    display: flex;
    margin-top: 30px;
    color: black;
    font-size: 25px;
    font-weight: 300;
} 