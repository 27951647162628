@media screen and (max-width: 500px) {
    section.reset-password-panel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding: 20px;
        background-color: #fff;
        border-radius: 15px;
        margin-bottom: 40px;
    }
}
   
   
@media screen and (min-width: 501px){
    section.reset-password-panel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 450px;
        padding: 20px;
        background-color: #fff;
        border-radius: 15px;
        margin-bottom: 40px;
    }
}





section.reset-password-panel .error-message {
    display: flex;
    justify-self: center;
    align-self: center;
    margin-top: 30px;
    font-size: 15px;
    color: red;
}
