


div.bracket-item{
    display: flex;
    flex-direction: column;
    background-color: black;
    border-radius: 20px;
    height: 80px;
    width: 80px;
    -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    overflow: hidden;
}

div.bracket-item .top{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 100%;
}
div.bracket-item .top .team{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 43%;
    padding-top: 5px;
    gap: 2px;
}

div.bracket-item .top .team .flag {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(89, 89, 89); */
    border-radius: 10px;
    height: 25px;
    width: 25px;
    overflow: hidden;
}

div.bracket-item .top .team .flag img{
    display: flex;
    justify-content: center;
    border-radius: 10px;
    height: 25px;
    width: 25px;
    overflow: hidden;
}

div.bracket-item .top .team .name {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 30px;
    font-size: 12px;
    color: white;
}

div.bracket-item .top .team .name .winner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 30px;
    font-size: 12px;
    font-weight: 500;
    color: white;
}


div.bracket-item .top .team .name .loser {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 30px;
    font-size: 12px;
   color: gray;
    text-decoration: line-through;
}


div.bracket-item .bottom  .winner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 30px;
    font-size: 12px;
    font-weight: 500;
    color: white;
}


div.bracket-item .bottom .loser {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 30px;
    font-size: 12px;
    color: gray;
}



div.bracket-item .bottom{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 100%;
    font-size: 13px;
    padding-bottom: 5px;
    color: gray;
}


