section.bracket-panel {
    display: flex;
    flex-direction: column;
    width: 350px;
    margin-top: 30px;
}


section.bracket-panel .row:nth-child(1){
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}


section.bracket-panel .row:nth-child(2){
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
}

section.bracket-panel .row:nth-child(3){
    display: flex;
    justify-content: center;
    width: 100%;
}

section.bracket-panel .row:nth-child(4){
    display: flex;
    justify-content: center;
    width: 100%;
}

section.bracket-panel .row:nth-child(5){
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
}
section.bracket-panel .row:nth-child(6){
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
}

section.bracket-panel .row:nth-child(7){
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}



section.bracket-panel .row .box{
    display: flex;
    justify-content: center;
    height: 100px;
    width: 170px;
}

section.bracket-panel .row:nth-child(2) .box{
    display: flex;
    justify-content: center;
    height: 100px;
    width: 260px;
}

section.bracket-panel .row:nth-child(3) .box{
    display: flex;
    justify-content: center;
    height: 100px;
    width: 80px;
}


section.bracket-panel .row:nth-child(4) .box{
    display: flex;
    justify-content: center;
    height: 100px;
    width: 80px;
}

section.bracket-panel .row:nth-child(5) .box{
    display: flex;
    justify-content: center;
    height: 100px;
    width: 80px;
}

section.bracket-panel .row:nth-child(6) .box{
    display: flex;
    justify-content: center;
    height: 100px;
    width: 260px;
}

section.bracket-panel .row .box .wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}

section.bracket-panel .row .box .wrapper .bracket-box{
    display: flex;
    justify-content: space-between;
    width: 100%;
}



section.bracket-panel .row .box .wrapper .lines{
    display: flex;
    justify-content: space-between;
    height: 20px;
    width: 92px;
    border-width: 0px 2px 2px 2px;
    border-style: solid;
    border-color: black;
}

section.bracket-panel .row .box .wrapper .line{
    display: flex;
    margin-right: calc(50% - 1px);
    height: 20px;
    width: 50%;
    border-width: 0px 2px 0px 0px;
    border-style: solid;
    border-color: black;
}

section.bracket-panel .row.part2 .box .wrapper .lines{
    display: flex;
    justify-content: space-between;
    height: 20px;
    width: 92px;
    border-width: 2px 2px 0px 2px;
    border-style: solid;
    border-color: black;
}


section.bracket-panel .row:nth-child(2) .box .wrapper .lines{
    display: flex;
    justify-content: space-between;
    height: 20px;
    width: 182px;
    border-width: 0px 2px 2px 2px;
    border-style: solid;
    border-color: black;
}

section.bracket-panel .row:nth-child(6) .box .wrapper .lines{
    display: flex;
    justify-content: space-between;
    height: 20px;
    width: 182px;
    border-width: 2px 2px 0px 2px;
    border-style: solid;
    border-color: black;
}