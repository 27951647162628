@media screen and (max-width: 500px) {  
    section.banner {
        position: relative;
        height:300px;
        background-color: #fff;
        overflow: hidden;
    }

    section.banner .banner-text {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        width: 90%;
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
        color: rgb(255, 255, 255);
        padding: 10px 20px;
        font-size: 20px;
        font-weight:700;
        backdrop-filter: blur(3px);
        box-shadow: 0px 0px 20px -1px rgba(66, 68, 90, 1);
    }
    
 }
    
    @media screen and (min-width: 501px) and (max-width: 700px) {
        section.banner {
            position: relative;
            height:350px;
            background-color: #fff;
            overflow: hidden;
        }
        section.banner .banner-text {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            width: 500px;
            top: 10%;
            left: 50%;
            transform: translateX(-50%);
            color: white;
            padding: 20px;
            font-size: 30px;
            font-weight:700;
            backdrop-filter: blur(3px);
            box-shadow: 0px 0px 20px -1px rgba(66, 68, 90, 1);
        }
    }
    
    
    @media screen and (min-width: 701px)  {
        section.banner {
            position: relative;
            height:600px;
            background-color: #fff;
            overflow: hidden;
        }

        section.banner .banner-text {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            width: 700px;
            top: 10%;
            left: 50%;
            transform: translateX(-50%);
            color: white;
            padding: 20px;
            font-size: 30px;
            font-weight:600;
            backdrop-filter: blur(3px);
            box-shadow: 0px 0px 20px -1px rgba(66, 68, 90, 1);
        }
        
    }

 
 
section.banner img {
    width: 100%;
    /* filter: blur(1px); */
 
}


section.banner a.start-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(6, 6, 6, 0.879);
    border: 2px solid white;
    border-radius: 30px;
    top: 70%;
    left: 50%;
    transform: translateX(-50%);
    width: 240px;
    height: 60px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    gap: 20px;
    transition: .3s;
}

section.banner a.start-button:hover {
    background-color: #fff;
    border: 2px solid black;
    color: black;
    font-size: 23px;
}

section.banner .start-button .play-icon {
    display: flex;
    font-size: 30px;
}

