@media screen and (max-width: 500px) {
    div.match-item{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        width: 340px;
        background-color: black;
        border-radius: 15px;
        margin-bottom: 10px;
        padding: 0px 20px 20px 20px;
        overflow: hidden;
        font-weight: 300;
    }
 }
    
    
@media screen and (min-width: 501px)  {
        div.match-item{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 80px;
            width: 400px;
            background-color: black;
            border-radius: 15px;
            margin-bottom: 10px;
            padding: 0px 20px 20px 20px;
            overflow: hidden;
            font-weight: 300;
        }
}





div.match-item .top{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 20px;
    border-radius: 0px 0px 5px 5px;
    width: 100px;
    color: black;
    background-color: #ece8e8;
}


div.match-item .bottom{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 10px 10px;
    height: 94px;
    width: 100%;
    /* background-color: red; */
}


div.match-item span.flag{
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    overflow: hidden;
    margin-left: 20px;
    margin-right: 20px;
}

div.match-item span.country-name{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 25px;
    color: white;
    font-size: 20px;
}

div.match-item span.separator{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 25px;
    color: white;
}
