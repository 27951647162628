
section.group-item {
            display: flex;
            width: 300px;
            text-align: center;
            flex-direction: column;
            margin-bottom: 20px;
            gap: 10px;
}
 
section.group-item .group-name {
    font-size: 18px;
}

section.group-item .group-table {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 0px 10px 0px 10px;
    -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
}

section.group-item .group-table .table-label{
    display: flex;
    width: 100%;
    height: 40px;
    font-weight: 500;
    border-bottom: 1px solid black;
}

section.group-item .group-table .table-label .rank{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
}

section.group-item .group-table .table-label .country{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
}
