@media screen and (max-width: 500px) {
   div.ranking {
      display: flex;
      flex-direction: column; 
      justify-content: center;
      align-items: center;
      padding: 15px;   
      border-radius: 15px;
      width: 100%;
      background-color: rgb(255, 255, 255);
      -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
      -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
      box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
   }
}
   
   
   @media screen and (min-width: 501px) and (max-width: 700px) {
      div.ranking {
         display: flex;
         flex-direction: column; 
         justify-content: center;
         align-items: center;
         padding: 15px;   
         border-radius: 15px;
         width: 400px;
         background-color: rgb(255, 255, 255);
         -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
         -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
         box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
      }
   }
   
   
   @media screen and (min-width: 701px)  {
      div.ranking {
         display: flex;
         flex-direction: column; 
         justify-content: center;
         align-items: center;
         padding: 15px;   
         border-radius: 15px;
         width: 450px;
         background-color: rgb(255, 255, 255);
         -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
         -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
         box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
      }
   }




 
 
