.official-score-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 500px) {
    div.official-matches-list {
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: center;
        padding: 15px;   
        border-radius: 15px;
        width: 340px;
        background-color: rgb(255, 255, 255);
        -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    }
 }
    
    
    @media screen and (min-width: 501px)  {
        div.official-matches-list {
            display: flex;
            flex-direction: column; 
            justify-content: center;
            align-items: center;
            padding: 15px;   
            border-radius: 15px;
            width: 400px;
            background-color: rgb(255, 255, 255);
            -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        }
    }
div.nav-scores {
    display: flex;
    justify-content: space-between;
    width: 340px;
    margin-bottom: 20px;
}

div.nav-scores .item{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 30%;
    border-radius: 30px;
    background-color: white;
    color: black;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
}

div.nav-scores .item-active{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 30%;
    border-radius: 30px;
    background-color: black;
    color: white;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
}

p.lack-of-official-matches {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    gap: 10px;
}

p.lack-of-official-matches .sad-icon {
    font-size: 24px;
}