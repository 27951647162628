@media screen and (max-width: 500px) {
    section.activate-account-panel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 20px;
        background-color: #fff;
        border-radius: 15px;
        margin-bottom: 40px;
    }
}
   
   
@media screen and (min-width: 501px){
    section.activate-account-panel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 450px;
        padding: 20px;
        background-color: #fff;
        border-radius: 15px;
        margin-bottom: 40px;
    }
}


section.activate-account-panel .activate-text{
    display: flex;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
}

section.activate-account-panel .activate-image{
    width: 50%;
    margin-bottom: 30px;
}
