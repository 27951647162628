div.player-bet-item {
    display: flex;
    position: relative;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    width: 100%;
    /* padding: 0px 20px; */
    border-radius: 15px;
    margin-bottom: 10px;
    /* background-color: rgb(41 33 33); */
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    color:white;
    font-weight: 300;
    overflow: hidden;
}

div.player-bet-item .right {
    display: flex;
    position: relative;
    justify-content: center;
    text-align: center;
    width: 60%;
    height: 60px;
    border-bottom: 60px solid rgb(24, 21, 21);
    border-left: 40px solid transparent;
    border-right: 0px solid transparent;
  }

div.player-bet-item .left {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    width: 40%;
  }

div.player-bet-item .name{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 30px;
    border-radius: 10px;
    color: black;
    overflow: hidden;
    font-size: 15px;
    font-weight: 400;
    margin-right: 18px;

}
@media screen and (max-width: 500px) {
    div.player-bet-item span.score{
        display: flex;
        position: relative;
        top: 15px;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        background-color: rgb(255, 255, 255);
        -webkit-box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
        color: black;
        border-radius: 10px;
        font-size: 20px;
    }
 }
    
    
    @media screen and (min-width: 501px)  {
        div.player-bet-item span.score{
            display: flex;
            position: relative;
            top: 12.5px;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            background-color: rgb(255, 255, 255);
            -webkit-box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            -moz-box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            color: black;
            border-radius: 10px;
            font-size: 20px;
        }
    }




div.player-bet-item  span.separator{
    display: flex;
    position: relative;
    top: 17.5px;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 25px;
    color: white;
}