@media screen and (max-width: 500px) {
    div.order-item {
        display: flex;
        flex-direction: column;
        width: 340px;
        height: 100px;
        border-radius: 15px;
        padding: 10px;
        background-color: #fff;
        -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        margin-bottom: 10px;
    }
    
    }
    
    
    @media screen and (min-width: 501px) and (max-width: 700px) {
        div.order-item {
            display: flex;
            flex-direction: column;
            width: 370px;
            height: 100px;
            border-radius: 15px;
            padding: 10px;
            background-color: #fff;
            -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            margin-bottom: 10px;
        }
        
    }
    
    
    @media screen and (min-width: 701px)  {
        div.order-item {
            display: flex;
            flex-direction: column;
            width: 450px;
            height: 100px;
            border-radius: 15px;
            padding: 10px;
            background-color: #fff;
            -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            margin-bottom: 10px;
        }
        
    }



div.order-item .top{
    display: flex;
    flex-direction: column;
    height: 25px;
    border-bottom: 0.5px solid rgb(180, 177, 177);
    color: rgb(116, 116, 116);
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 300;
}
div.order-item .bottom{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 55px;
}

div.order-item .bottom .item{
    display: flex;
    flex-direction: column;
}


div.order-item .bottom .item .label{
    display: flex;
    color: black;
    font-weight: 400;
}

div.order-item .bottom .item .item-content{
    display: flex;
    color: rgb(116, 116, 116);
    font-weight: 300;
}

