label.radio-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 320px;
    margin-bottom: 10px;
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
}

label.radio-input .name{
    display: flex;
    margin-right: 10px;
}
label.radio-input .icon{
    display: flex;
    margin-right: 5px;
    margin-left: 10px;
}

label.radio-input  input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-right: 20px;
    color: black;
}

label.radio-input input[type='radio'] {
    accent-color: #232323;
}