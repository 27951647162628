@media screen and (max-width: 500px) {

    .article {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items:start;
        margin-top: 20px;
        width: 350px;
        /* height: 400px; */
        background-color: white;
        border-radius: 30px;
        padding: 15px 15px 20px 15px;
        box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
        text-align: justify;
        hyphens: auto;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }
    .article .box {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    } 

    .article .image{
        position: relative;
        width: 100%;
        height: 190px;
        overflow: hidden;
        border-radius: 20px;
        margin-bottom: 20px;
    }

    .article .text{
    margin-left: 10px;
    margin-right: 10px;
    }   

    .article .image img{
        width: 100%;
    }
    
    .article span.title-mini {
        position: absolute;
        top: 10px;
        left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 17px;
        font-weight: 300;
        padding: 5px 20px 5px 20px;
        border-radius: 20px;
        box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
        background-color: rgb(255, 255, 255);
        color: black;
    }
    
    .article .icon-home{
        font-size: 25px;
    }
    
    
    .article .instruction-panel {
        display: flex;
        align-items: center;
        width: 100%;
        height: 60px;
        /* margin-left: 10px; */
        margin-bottom: 10px;
        border-radius: 30px;
        gap: 20px;
        background-color: white;
        box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
        padding-left: 20px;
    }
    
    .article .instruction-panel .number {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: black;
        color: white;
    }
    
    .article .instruction-panel .info {
        display: flex;
    }
    .article .additional {
        padding: 20px;
        font-size: 14px;
    }
    
    
    .article .rules-panel {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        /* height: 200px; */
        margin-bottom: 10px;
        border-radius: 30px;
        background-color: white;
        box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
        padding: 20px;
    
    }
    
    .article .rules-panel .top {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 10px;
    
    }
    
    .article .rules-panel .bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    
    }
    
    .article .rules-panel .top  .number {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: black;
        color: white;
    }
    
    .article .rules-panel .top .info {
        display: flex;
    }
    
    .article .rules-panel .bottom ul {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-left: 20px;
        gap: 5px;
        font-size: 14px;
    
    }
    
    .article .rules-panel .bottom .example-text {
        display: flex;
        align-self: baseline;
        background-color: black;
        color: white;
        margin-top: 30px;
        padding: 5px 15px 5px 15px;
        border-radius: 20px;
    }
    
    .article .rules-panel .bottom .example-panel {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        font-size: 14px;
    }
    
    .article .rules-panel .bottom .container {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
    }
    
    .article .rules-panel .bottom .container .left {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
    }
    .article .rules-panel .bottom .container .right {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-left: 20px;
        width: 220px;
        padding: 10px 0px;
        border-radius: 20px;
        background-color: #fff;
        box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
    }
    .article .rules-panel .bottom .container .right .example {
        display: flex;
        width: 180px;
    }
    
    .article .rules-panel .bottom .container .left .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
    }
    
    .article .rules-panel .bottom .container .left .icon-container {    
        font-size: 27px;
    }
    .article .contact-panel {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background-color: white;
        box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
        padding: 20px;
        border-radius: 20px;
        gap: 30px;
    }

    .article .contact-panel .email {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        width: 100%;
        background-color: white;
        box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
        padding: 20px;
        border-radius: 20px;
    }

    .article .contact-panel .email-icon {
        font-size: 25px;
    }
    .article .contact-panel .email-text {
        font-size: 15px;
    }
    
}
    
    
    
@media screen and (min-width: 501px) and (max-width: 700px) {
        .article {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items:start;
            margin-top: 20px;
            width: 400px;
            /* height: 400px; */
            background-color: white;
            border-radius: 30px;
            padding: 15px 15px 20px 15px;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            text-align: justify;
            hyphens: auto;
            word-wrap: break-word;
            overflow-wrap: break-word;
        }
        .article .box {
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        } 
    
        .article .image{
            position: relative;
            width: 100%;
            height: 190px;
            overflow: hidden;
            border-radius: 20px;
            margin-bottom: 20px;
        }
    
        .article .text{
        margin-left: 10px;
        margin-right: 10px;
        }   
    
        .article .image img{
            width: 100%;
        }
        
        .article span.title-mini {
            position: absolute;
            top: 10px;
            left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            font-size: 17px;
            font-weight: 300;
            padding: 5px 20px 5px 20px;
            border-radius: 20px;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            background-color: rgb(255, 255, 255);
            color: black;
        }
        
        .article .icon-home{
            font-size: 25px;
        }
        
        
        .article .instruction-panel {
            display: flex;
            align-items: center;
            width: 100%;
            height: 60px;
            /* margin-left: 10px; */
            margin-bottom: 10px;
            border-radius: 30px;
            gap: 20px;
            background-color: white;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            padding-left: 20px;
        }
        
        .article .instruction-panel .number {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            background-color: black;
            color: white;
        }
        
        .article .instruction-panel .info {
            display: flex;
        }
        .article .additional {
            padding: 20px;
            font-size: 14px;
        }
        
        
        .article .rules-panel {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            /* height: 200px; */
            margin-bottom: 10px;
            border-radius: 30px;
            background-color: white;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            padding: 20px;
        
        }
        
        .article .rules-panel .top {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 10px;
        
        }
        
        .article .rules-panel .bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        
        }
        
        .article .rules-panel .top  .number {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            background-color: black;
            color: white;
        }
        
        .article .rules-panel .top .info {
            display: flex;
        }
        
        .article .rules-panel .bottom ul {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            margin-left: 20px;
            gap: 5px;
            font-size: 14px;
        
        }
        
        .article .rules-panel .bottom .example-text {
            display: flex;
            align-self: baseline;
            background-color: black;
            color: white;
            margin-top: 30px;
            padding: 5px 15px 5px 15px;
            border-radius: 20px;
        }
        
        .article .rules-panel .bottom .example-panel {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            font-size: 14px;
        }
        
        .article .rules-panel .bottom .container {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
        }
        
        .article .rules-panel .bottom .container .left {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
        }
        .article .rules-panel .bottom .container .right {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-left: 20px;
            width: 220px;
            padding: 10px 0px;
            border-radius: 20px;
            background-color: #fff;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
        }
        .article .rules-panel .bottom .container .right .example {
            display: flex;
            width: 180px;
        }
        
        .article .rules-panel .bottom .container .left .icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
        }
        
        .article .rules-panel .bottom .container .left .icon-container {    
            font-size: 27px;
        }
        .article .contact-panel {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            background-color: white;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            padding: 20px;
            border-radius: 20px;
            gap: 30px;
        }

        .article .contact-panel .email {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 20px;
            width: 100%;
            background-color: white;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            padding: 20px;
            border-radius: 20px;
        }

        .article .contact-panel .email-icon {
            font-size: 25px;
        }
        .article .contact-panel .email-text {
            font-size: 15px;
        }
        
}
    
    
    
@media screen and (min-width: 701px) and (max-width: 950px) {
        .article {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items:start;
            justify-content: space-around;
            margin-top: 20px;
            width: 90vw;
            background-color: white;
            border-radius: 30px;
            padding: 15px;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            text-align: justify;
            hyphens: auto;
            word-wrap: break-word;
            overflow-wrap: break-word;
        }

        .article .box {
            display: flex;
            width: 50%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        } 

        .article .image{
            display: flex;
            position: relative;
            width: 45%;
            /* height: 300px; */
            overflow: hidden;
            border-radius: 20px;
        }

        .article .text{
            display: flex;
            width: 100%;
            margin-left: 10px;
            /* margin-right: 10px; */
        }

        .article .image img{
            width: 100%;
        }
        
        .article span.title-mini {
            position: absolute;
            top: 10px;
            left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            font-size: 17px;
            font-weight: 300;
            padding: 5px 20px 5px 20px;
            border-radius: 20px;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            background-color: rgb(255, 255, 255);
            color: black;
        }
        
        .article .icon-home{
            font-size: 25px;
        }
        
        
        .article .instruction-panel {
            display: flex;
            align-items: center;
            width: 100%;
            height: 60px;
            /* margin-left: 10px; */
            margin-bottom: 10px;
            border-radius: 30px;
            gap: 20px;
            background-color: white;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            padding-left: 20px;
        }
        
        .article .instruction-panel .number {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            background-color: black;
            color: white;
        }
        
        .article .instruction-panel .info {
            display: flex;
        }
        .article .additional {
            padding: 20px;
            font-size: 14px;
        }
        
        
        .article .rules-panel {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            /* height: 200px; */
            margin-bottom: 10px;
            border-radius: 30px;
            background-color: white;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            padding: 20px;
        
        }
        
        .article .rules-panel .top {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 10px;
        
        }
        
        .article .rules-panel .bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        
        }
        
        .article .rules-panel .top  .number {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            background-color: black;
            color: white;
        }
        
        .article .rules-panel .top .info {
            display: flex;
        }
        
        .article .rules-panel .bottom ul {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            margin-left: 20px;
            gap: 5px;
            font-size: 14px;
        
        }
        
        .article .rules-panel .bottom .example-text {
            display: flex;
            align-self: baseline;
            background-color: black;
            color: white;
            margin-top: 30px;
            padding: 5px 15px 5px 15px;
            border-radius: 20px;
        }
        
        .article .rules-panel .bottom .example-panel {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            font-size: 14px;
        }
        
        .article .rules-panel .bottom .container {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
        }
        
        .article .rules-panel .bottom .container .left {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
        }
        .article .rules-panel .bottom .container .right {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-left: 20px;
            width: 220px;
            padding: 10px 0px;
            border-radius: 20px;
            background-color: #fff;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
        }
        .article .rules-panel .bottom .container .right .example {
            display: flex;
            width: 180px;
        }
        
        .article .rules-panel .bottom .container .left .icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
        }
        
        .article .rules-panel .bottom .container .left .icon-container {    
            font-size: 27px;
        }
        .article .contact-panel {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            background-color: white;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            padding: 20px;
            border-radius: 20px;
            gap: 30px;
        }

        .article .contact-panel .email {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 20px;
            width: 100%;
            background-color: white;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            padding: 20px;
            border-radius: 20px;
        }

        .article .contact-panel .email-icon {
            font-size: 25px;
        }
        .article .contact-panel .email-text {
            font-size: 15px;
        }
        
    }


@media screen and (min-width: 951px) {
        .article {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items:start;
            justify-content: space-around;
            margin-top: 20px;
            width: 860px;
            background-color: white;
            border-radius: 30px;
            padding: 15px;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            text-align: justify;
            hyphens: auto;
            word-wrap: break-word;
            overflow-wrap: break-word;
        }

        .article .box {
            display: flex;
            width: 400px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        } 

        .article .image{
            display: flex;
            position: relative;
            width: 400px;
            /* height: 300px; */
            overflow: hidden;
            border-radius: 20px;
        }

        .article .text{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-left: 10px;
            /* margin-right: 10px; */
        }

        .article .image img{
            width: 100%;
        }
        
        .article span.title-mini {
            position: absolute;
            top: 10px;
            left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            font-size: 17px;
            font-weight: 300;
            padding: 5px 20px 5px 20px;
            border-radius: 20px;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            background-color: rgb(255, 255, 255);
            color: black;
        }
        
        .article .icon-home{
            font-size: 25px;
        }
        
        
        .article .instruction-panel {
            display: flex;
            align-items: center;
            width: 100%;
            height: 60px;
            /* margin-left: 10px; */
            margin-bottom: 10px;
            border-radius: 30px;
            gap: 20px;
            background-color: white;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            padding-left: 20px;
        }
        
        .article .instruction-panel .number {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            background-color: black;
            color: white;
        }
        
        .article .instruction-panel .info {
            display: flex;
        }
        .article .additional {
            padding: 20px;
            font-size: 14px;
        }
        
        
        .article .rules-panel {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            /* height: 200px; */
            margin-bottom: 10px;
            border-radius: 30px;
            background-color: white;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            padding: 20px;
        
        }
        
        .article .rules-panel .top {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 10px;
        
        }
        
        .article .rules-panel .bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        
        }
        
        .article .rules-panel .top  .number {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            background-color: black;
            color: white;
        }
        
        .article .rules-panel .top .info {
            display: flex;
        }
        
        .article .rules-panel .bottom ul {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            margin-left: 20px;
            gap: 5px;
            font-size: 14px;
        
        }
        
        .article .rules-panel .bottom .example-text {
            display: flex;
            align-self: baseline;
            background-color: black;
            color: white;
            margin-top: 30px;
            padding: 5px 15px 5px 15px;
            border-radius: 20px;
        }
        
        .article .rules-panel .bottom .example-panel {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            font-size: 14px;
        }
        
        .article .rules-panel .bottom .container {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
        }
        
        .article .rules-panel .bottom .container .left {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
        }
        .article .rules-panel .bottom .container .right {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-left: 20px;
            width: 220px;
            padding: 10px 0px;
            border-radius: 20px;
            background-color: #fff;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
        }
        .article .rules-panel .bottom .container .right .example {
            display: flex;
            width: 180px;
        }
        
        .article .rules-panel .bottom .container .left .icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
        }
        
        .article .rules-panel .bottom .container .left .icon-container {    
            font-size: 27px;
        }

        .article .contact-panel {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 240px;
            background-color: white;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            padding: 20px;
            border-radius: 20px;
            gap: 30px;
        }

        .article .contact-panel .email {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 20px;
            width: 100%;
            background-color: white;
            box-shadow: 0px 0px 20px -10px rgba(66, 68, 90, 1);
            padding: 20px;
            border-radius: 20px;
        }

        .article .contact-panel .email-icon {
            font-size: 25px;
        }
        .article .contact-panel .email-text {
            font-size: 15px;
        }

        
        
    }





    