@media screen and (max-width: 500px) {
    div.search-match-panel {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 340px;
        background-color: white;
        border-radius: 10px;
        -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        margin-bottom: 25px;
        padding-left: 13px;
        padding-right: 13px;
        min-width: 240px;
    }
 }
    
    
@media screen and (min-width: 501px)  {
    div.search-match-panel {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 400px;
        background-color: white;
        border-radius: 10px;
        -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        margin-bottom: 25px;
        padding-left: 13px;
        padding-right: 13px;
        min-width: 240px;
    }
}



div.search-match-panel .icon-search{
    font-size: 34px;
    margin-right: 12px;
}


div.search-match-panel select{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    appearance: none; 
    -webkit-appearance: none; 
    -moz-appearance: none; 
    outline: none;
    border: none; 
    background-color: transparent;
    padding: 0; 
    font-size: 15px;
    color: black;
}