@media screen and (max-width: 380px) {
    div.ranking-label {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 50px;
        padding: 0px 20px;
        border-radius: 15px;
        margin-bottom: 10px;
        background-color: rgb(255, 255, 255);
        -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        color:black;
        font-weight: 300;
        font-size: 18px;
    }

    div.ranking-label span.rank  {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
    }
    div.ranking-label span.name  {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90px;
        overflow: hidden;
    }
 }


@media screen and (min-width: 381px) and (max-width: 500px) {
    div.ranking-label {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 50px;
        padding: 0px 20px;
        border-radius: 15px;
        margin-bottom: 10px;
        background-color: rgb(255, 255, 255);
        -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        color:black;
        font-weight: 300;
        font-size: 18px;
    }
    div.ranking-label span.rank  {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
    }
    
    div.ranking-label span.name  {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        overflow: hidden;
    }
 }
    
    
    @media screen and (min-width: 501px) and (max-width: 700px) {
        div.ranking-label {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 400px;
            height: 50px;
            padding: 0px 20px;
            border-radius: 15px;
            margin-bottom: 10px;
            background-color: rgb(255, 255, 255);
            -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            color:black;
            font-weight: 300;
            font-size: 18px;
        }
        div.ranking-label span.rank  {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
        }
        
        div.ranking-label span.name  {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 120px;
            overflow: hidden;
        }
    }
    
    
    @media screen and (min-width: 701px)  {
        div.ranking-label {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 450px;
            height: 50px;
            padding: 0px 20px;
            border-radius: 15px;
            margin-bottom: 10px;
            background-color: rgb(255, 255, 255);
            -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            color:black;
            font-weight: 300;
            font-size: 18px;
        }
        div.ranking-label span.rank  {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
        }
        
        div.ranking-label span.name  {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 120px;
            overflow: hidden;
        }
    }
 



div.ranking-label span.wrapper   {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 110px;
    height: 30px;
}

div.ranking-label span.wrapper span.box  {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
}
div.ranking-label span.total-prize {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
}
