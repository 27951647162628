
form.reset-password-content .title-reset {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 400;
    color: rgb(38, 38, 38);
    margin: 30px 0px 50px 0px;
}


form.reset-password-content .error-message {
    margin-top: 30px;
    font-size: 15px;
    color: red;
}